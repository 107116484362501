import React from "react";
import PropTypes from "prop-types";
import { Mutation } from "@apollo/client/react/components";
import { gql } from "@apollo/client";


class ApplyButton extends React.Component {
  state = {
    isSending: false
  };

  render() {
    const { row } = this.props;

    return (
      row.data.appliable && (
        <Mutation mutation={APPLY_EPI_IMPORT} onError={this.onError}>
          {(update, { loading, error }) => (
            <button
              onClick={e => {
                e.preventDefault();
                this.send(update);
              }}
              className="button button--small button--green"
              disabled={this.state.isSending}
              style={{ marginRight: 5 }}
            >
              {this.state.isSending ? "Applying..." : "Apply Updates"}
            </button>
          )}
        </Mutation>
      )
    );
  }

  send = update => {
    const { row } = this.props;

    if (window.confirm("Are you sure you want to apply these product updates?")) {
      this.setState({ isSending: true });
      update({
        variables: {
          id: row.data.id
        }
      });
    }
  };
}

const APPLY_EPI_IMPORT = gql`
  mutation applyEpiImport($id: ID!) {
    applyEpiImport(id: $id) {
      epiImport {
        id
        contract
        createdAt
        status
        errorDetails
        viewLink
        appliable
        revertable
      }
      errors {
        path
        message
      }
    }
  }
`;

ApplyButton.propTypes = {
  row: PropTypes.object.isRequired
};

export default ApplyButton;
