import React from "react";
import PropTypes from "prop-types";

import { HealthcareProviderObject, OrderObject } from "./../Queries/OrderQuery";
import HcpView from "./HcpView";
import HcpUpdater from "./HcpUpdater";
import HcpModal from "./Modal";
import { isErapOrder } from "../BusRules/access";

class HealthcareProvider extends React.Component {
  state = { editing: false };

  render() {
    const { hcp, order, editable, editableAfterGP, currentUser } = this.props;

    if (hcp == null) {
      if (!["draft", "cancelled"].includes(order.status)) {
        return <div>loading...</div>;
      } else {
        return (
          <article className="order-hcp-area">
            <header className="display_group__header">
              <h2 className="display_group__header__heading">
                Healthcare Provider Details
              </h2>
              <HcpModal order={order} currentUser={currentUser} />
            </header>
          </article>
        );
      }
    }

    const canEdit =
      (editable || editableAfterGP) && order.uiState.currentUserIsHeadOffice;
    const limitedEditableFields = editableAfterGP && !editable;

    return (
      <article className="order-hcp-area">
        <header className="display_group__header">
          <h2 className="display_group__header__heading">
            Healthcare Provider Details
          </h2>
          {canEdit &&
            !isErapOrder(order) &&
            ["draft", "submitted", "pending_validation"].includes(
              order.status
            ) && (
              <HcpModal
                order={order}
                currentUser={currentUser}
                disabled={this.state.editing}
              />
            )}
          {canEdit && !order.uiState.clientOrderWithDefaultHcp && (
            <a
              className="display_group__header__button"
              onClick={this.toggleEdit}
            >
              {this.state.editing ? "Cancel" : "Edit"}
            </a>
          )}
        </header>
        {this.state.editing ? (
          <HcpUpdater
            {...this.props}
            toggleEdit={this.toggleEdit}
            limitedEditableFields={limitedEditableFields}
          />
        ) : (
          <HcpView hcp={hcp} order={order} currentUser={currentUser} />
        )}
      </article>
    );
  }

  toggleEdit = () => {
    this.setState(prevState => {
      this.props.disableUIFunc(!prevState.editing);
      return { editing: !prevState.editing };
    });
  };
}

HealthcareProvider.propTypes = {
  hcp: HealthcareProviderObject,
  order: OrderObject.isRequired,
  currentUser: PropTypes.object.isRequired,
  editable: PropTypes.bool.isRequired,
  disableUIFunc: PropTypes.func.isRequired,
  editableAfterGP: PropTypes.bool.isRequired
};

export default HealthcareProvider;
