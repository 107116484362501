import React from "react";
import PropTypes from "prop-types";
import { gql } from "@apollo/client";

import CmpAgGrid from "../lib/AgGrid/CmpAgGrid";
import CmpAgGridLink from "../lib/AgGrid/CmpAgGridLink";
import ApplyButton from "./ApplyButton";
import RevertButton from "./RevertButton";

class EpiImportsTable extends React.Component {
  render() {
    return (
      <CmpAgGrid
        userId={this.props.currentUser.id}
        defaultColumnDefs={this.defaultColumnDefs()}
        gqlQuery={this.epiImportsQuery}
        responseDataKey="epiImports"
      />
    );
  }

  defaultColumnDefs = () => [
    {
      headerName: "Contract",
      field: "contract",
      width: 150,
      filter: "agSetColumnFilter",
      filterParams: {
        values: this.props.contractCodes,
        comparator: (a, b) => a.toLowerCase().localeCompare(b.toLowerCase()),
        excelMode: "windows"
      }
    },
    {
      headerName: "Imported At",
      field: "createdAt",
      width: 180,
      sort: "desc",
      filter: "agDateColumnFilter",
      filterParams: {
        suppressAndOrCondition: true,
        filterOptions: ["equals", "lessThan", "greaterThan"]
      }
    },
    {
      headerName: "Status",
      field: "status",
      width: 150,
      filter: "agSetColumnFilter",
      filterParams: {
        values: ["Processing", "Processed", "Applied", "Reverted", "Warning", "Error", "Closed"],
        suppressSorting: true
      }
    },
    {
      headerName: "Error Details",
      field: "errorDetails",
      flex: 1,
      filter: "agTextColumnFilter",
      sortable: false,
      cellRenderer: CmpAgGridLink,
      cellRendererParams: params => {
        const value = params.data.errorDetails || "None";
        return {
          link: params.data.viewLink,
          value: value
        };
      }
    },
    {
      headerName: "Action",
      width: 230,
      field: "appliable",
      cellRenderer: params => {
        return (
          <React.Fragment key={params.data.id}>
            <ApplyButton row={params} />
            <RevertButton row={params} />
          </React.Fragment>
        );
      }
    }
  ];

  epiImportsQuery = () => {
    return gql`
      query EpiImports($agGridParams: RequestParamInput!) {
        epiImports(agGridParams: $agGridParams) {
          id
          contract
          createdAt
          status
          errorDetails
          viewLink
          appliable
          revertable
        }
      }
    `;
  };
}

EpiImportsTable.propTypes = {
  contractCodes: PropTypes.array.isRequired,
  currentUser: PropTypes.object
};

export default EpiImportsTable;
