import React, { useState, useMemo } from "react";

import { OrderObject } from "../Queries/OrderQuery";
import PropTypes from "prop-types";
import { AgGridReact } from "ag-grid-react";
import ProcessOrderLinesUpdater from "../OrderLevelProcessBtns/ProcessOrderLinesUpdater";
import { lineStatuses } from "../BusRules/orderLines";

const trialStatusEditorParams = params => {
  return {
    values: params.data.trialStatusOptions
  };
};

const TrialLinesTable = ({ order, closeModal, disableUIFunc }) => {
  const [rowData, setRowData] = useState(
    order.orderLines
      .filter(
        line =>
          line.saleType !== "FREIGHT" &&
          !line.isHomeMod &&
          !line.isRepair &&
          !lineStatuses.TRIAL_READ_ONLY_LIST.includes(line.status)
      )
      .map(d => ({ ...d }))
  );

  const getRowId = useMemo(() => {
    return params => params.data.id;
  }, []);

  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "Cat Num",
      field: "sku",
      width: 120
    },
    {
      headerName: "Description",
      field: "description",
      flex: 1
    },
    {
      headerName: "Trial Status",
      field: "trialStatus",
      width: 150,
      editable: true,
      cellEditor: "agRichSelectCellEditor",
      cellEditorParams: trialStatusEditorParams,
      cellRenderer: params => (
        <div>
          {params.value}
          <div className="icon icon--edit icon--right icon--tiny icon--mtop" />
        </div>
      )
    }
  ]);

  const [defaultColDef, setDefaultColDef] = useState({
    resizable: false,
    sortable: true,
    suppressHeaderMenuButton: true,
    floatingFilter: false,
    singleClickEdit: true,
    filter: false
  });

  const sendUpdate = processLines => {
    const lines = rowData.map(({ id, trialStatus }) => ({ id, trialStatus }));
    processLines(order.id, lines, {});
    closeModal();
  };

  return (
    <ProcessOrderLinesUpdater
      disableUIFunc={disableUIFunc}
      render={({ processLines }) => (
        <React.Fragment>
          <div
            id="trial-lines-table"
            className="ag-theme-balham ag-grid-wrapper auto-height"
          >
            <AgGridReact
              rowData={rowData}
              getRowId={getRowId}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              editType="fullRow"
              stopEditingWhenCellsLoseFocus={true}
              domLayout="autoHeight"
            />
          </div>
          <footer className="modal__footer">
            <div>
              <button
                onClick={() => closeModal()}
                className="button button--back"
              >
                Cancel
              </button>
              <button
                onClick={() => sendUpdate(processLines)}
                className="button primary"
              >
                Update
              </button>
            </div>
          </footer>
        </React.Fragment>
      )}
    />
  );
};

TrialLinesTable.propTypes = {
  order: OrderObject.isRequired,
  closeModal: PropTypes.func.isRequired,
  disableUIFunc: PropTypes.func.isRequired
};

export default TrialLinesTable;
