import React from "react";
import PropTypes from "prop-types";
import { Mutation } from "@apollo/client/react/components";
import { gql } from "@apollo/client";


class RevertButton extends React.Component {
  state = {
    isSending: false
  };

  render() {
    const { row } = this.props;

    return (
      row.data.revertable && (
        <Mutation mutation={REVERT_EPI_IMPORT} onError={this.onError}>
          {(update, { loading, error }) => (
            <button
              onClick={e => {
                e.preventDefault();
                this.send(update);
              }}
              className="button button--small red"
              disabled={this.state.isSending}
              style={{ marginRight: 5 }}
            >
              {this.state.isSending ? "Reverting..." : "Revert Updates"}
            </button>
          )}
        </Mutation>
      )
    );
  }

  send = update => {
    const { row } = this.props;

    if (window.confirm("Are you sure you want to revert these product updates?")) {
      this.setState({ isSending: true });
      update({
        variables: {
          id: row.data.id
        }
      });
    }
  };
}

const REVERT_EPI_IMPORT = gql`
  mutation revertEpiImport($id: ID!) {
    revertEpiImport(id: $id) {
      epiImport {
        id
        contract
        createdAt
        status
        errorDetails
        viewLink
        appliable
        revertable
      }
      errors {
        path
        message
      }
    }
  }
`;

RevertButton.propTypes = {
  row: PropTypes.object.isRequired
};

export default RevertButton;
