import React from "react";
import PropTypes from "prop-types";

import { OrderObject } from "./../Queries/OrderQuery";

import RoField from "Form/RoField";
import RoDateField from "Form/RoDateField";
import RoDateTimeField from "Form/RoDateTimeField";
import RoFieldWrapper from "Form/RoFieldWrapper";
import EditSalesRep from "./EditSalesRep";
import EditDateField from "Form/EditDateField";
import EditTextField from "Form/EditTextField";
import EditTextArea from "Form/EditTextArea";
import EditYesNo from "Form/EditYesNo";
import {
  isHireOrder,
  isAgedCareOrder,
  boolFieldYesNo,
  isHospitalOrder,
  isHospitalDischargeOrder
} from "./../BusRules/orders";
import { allowPrivilegedFields, isOnDvaContract } from "./../BusRules/access";

import { OrderLink } from "./../OrderNav";
import EditDebtor from "./EditDebtor";
import SelectDebtorQuery from "../Lines/Assign/SelectDebtorQuery";
import RoYesNo from "../../lib/Form/RoYesNo";
import CostCentresQuery from "./CostCentresQuery";
import EditDropdown from "../../lib/Form/EditDropdown";
class OrderDetailsEdit extends React.Component {
  state = {
    order: this.props.order
  };

  render() {
    const { order } = this.state;
    const { errors } = this.props || [];
    const { currentUser, limitedEditableFields } = this.props;
    const cpoEditable = order.uiState.allowEditCpo && !limitedEditableFields;

    return (
      <form onSubmit={this.onSubmit} className={"form"}>
        {/* <ErrorDisplay errors={errors} modelDesc="order" /> */}
        <div className={"display-flex"}>
          <fieldset className={"fieldset fieldset--compact fieldset--edit"}>
            <RoField
              value={order.statusDesc}
              dataLabelClass={order.status}
              labelText="Status"
            />
            <RoField value={order.cmpOrderNumber} labelText="CMP Order No" />
            <RoField value={order.orderType} labelText="Order Type" />
            {isOnDvaContract(order) &&
              order.includesHomeMods &&
              allowPrivilegedFields(order) &&
              order.uiState.allowEditComplexHomeMods && (
                <EditYesNo
                  name="complexHomeMods"
                  onChange={this.handleChange}
                  value={order.complexHomeMods}
                  labelText="Complex Home Mod"
                  errors={errors}
                />
              )}
            {isOnDvaContract(order) &&
              order.includesHomeMods &&
              allowPrivilegedFields(order) &&
              !order.uiState.allowEditComplexHomeMods && (
                <RoYesNo
                  value={order.complexHomeMods}
                  labelText="Complex Home Mod"
                />
              )}
            <RoField value={order.webOrderNo} labelText="Web Order No" />
            <RoField value={order.sourceSystem} labelText="Originated" />
            <RoField value={order.contract.contractCode} labelText="Contract" />
            {order.contract.clientOrdersAllowed && (
              <RoYesNo value={order.clientOrder} labelText="Client Order" />
            )}
            {allowPrivilegedFields(order) && isAgedCareOrder(order) && (
              <RoField
                value={order.contract.contractType}
                labelText="Contract Type"
              />
            )}
            {allowPrivilegedFields(order) &&
              order.contract.contractType === "Hospital" && (
                <RoField value={order.hospitalType} labelText="Hospital Type" />
              )}
            {isHospitalDischargeOrder(order) &&
              allowPrivilegedFields(order) && (
                <EditYesNo
                  name="vic30DayDischarge"
                  onChange={this.handleChange}
                  value={order.vic30DayDischarge}
                  labelText="Vic 30 Day Discharge"
                  labelClass="field--required"
                  includeBlank={true}
                  errors={errors}
                />
              )}
            {isHospitalDischargeOrder(order) &&
              !allowPrivilegedFields(order) &&
              order.vic30DayDischarge && (
                <RoYesNo
                  value={order.vic30DayDischarge}
                  labelText="Vic 30 Day Discharge"
                />
              )}
            {allowPrivilegedFields(order) && order.status === "draft" && (
              <EditDateField
                name="dateReceived"
                onChange={this.handleChange}
                value={order.dateReceived}
                labelText="Order Date"
                errors={errors}
                required={true}
              />
            )}
            {!(allowPrivilegedFields(order) && order.status === "draft") && (
              <RoField value={order.orderDate} labelText="Order Date" />
            )}
            {allowPrivilegedFields(order) && !limitedEditableFields && isOnDvaContract(order) && (
              <EditDateField
                name="priorApprovalEffectedAt"
                onChange={this.handleChange}
                value={order.dateReceived}
                labelText="Order Received Date"
                errors={errors}
              />
            )}
            {allowPrivilegedFields(order) && limitedEditableFields && isOnDvaContract(order) && (
              <RoDateField
                value={order.dateReceived}
                labelText="Order Received Date"
              />
            )}
            {allowPrivilegedFields(order) && (
              <EditDateField
                name="followUpOn"
                onChange={this.handleChange}
                value={order.followUpOn}
                labelText="Follow Up Date"
                limit="TodayForward"
                errors={errors}
              />
            )}
            {!allowPrivilegedFields(order) && (
              <EditDateField
                name="npFollowUpOn"
                onChange={this.handleChange}
                value={order.npFollowUpOn}
                labelText="Follow Up Date"
                limit="TodayForward"
                errors={errors}
              />
            )}
            {allowPrivilegedFields(order) && !limitedEditableFields && (
              <EditTextField
                name="priorApprovalNumber"
                onChange={this.handleChange}
                value={order.priorApprovalNumber}
                errors={errors}
                labelText="Prior Approval #"
              />
            )}
            {allowPrivilegedFields(order) && limitedEditableFields && (
              <RoField
                value={order.priorApprovalNumber}
                labelText="Prior Approval #"
              />
            )}
            <RoField value={order.invoiceNo} labelText="Invoice No" />
            {allowPrivilegedFields(order) &&
              order.uiState.allowEditCostCentre &&
              isHospitalOrder(order) && (
                <CostCentresQuery
                  order={order}
                  render={({ costCentres }) => (
                    <EditDropdown
                      name="costCentre"
                      onChange={this.handleChange}
                      value={order.costCentre}
                      errors={errors}
                      labelText="Cost Centre"
                      required={isHospitalOrder(order)}
                      options={costCentres}
                    />
                  )}
                />
              )}
            {allowPrivilegedFields(order) &&
              !order.uiState.allowEditCostCentre &&
              isHospitalOrder(order) && (
                <RoField value={order.costCentre} labelText="Cost Centre" />
              )}
            {allowPrivilegedFields(order) &&
              cpoEditable &&
              !isOnDvaContract(order) && (
                <EditTextField
                  name="cpo"
                  onChange={this.handleChange}
                  value={order.cpo}
                  errors={errors}
                  labelText="CPO"
                />
              )}
            {allowPrivilegedFields(order) &&
              !cpoEditable &&
              !isOnDvaContract(order) && (
                <RoField value={order.cpo} labelText="CPO" />
              )}
            {allowPrivilegedFields(order) && !limitedEditableFields && (
              <EditYesNo
                name="urgent"
                onChange={this.handleChange}
                value={order.urgent}
                labelText="Urgent"
                errors={errors}
              />
            )}
            {order.uiState.showAwaitingCall && (
              <RoField
                value={boolFieldYesNo(order.awaitingCall)}
                dataLabelClass={order.awaitingCall ? "urgent" : ""}
                labelText="Awaiting Call"
              />
            )}
            {!allowPrivilegedFields(order) && !limitedEditableFields && (
              <RoField
                value={boolFieldYesNo(order.urgent)}
                dataLabelClass={order.urgent ? "urgent" : ""}
                labelText="Urgent"
              />
            )}
            <RoField value={order.assignedStaffName} labelText="Aidacare Staff" />
            {order.uiState.hasSalesRep &&
              allowPrivilegedFields(order) && 
              !limitedEditableFields && (
                <EditSalesRep
                  onChange={this.handleChange}
                  value={(order.salesRep && order.salesRep.name) || null}
                  labelText="Sales Rep"
                  options={this.props.salesReps}
                />
              )}
            {order.uiState.hasSalesRep &&
              allowPrivilegedFields(order) &&
              limitedEditableFields && (
                <RoField value={order.salesRep.name} labelText="Sales Rep" />
              )}
            {order.uiState.canEditDebtor && (
              <SelectDebtorQuery
                contractId={order.contract.id}
                render={({ debtors }) => (
                  <EditDebtor
                    onChange={this.handleChange}
                    value={(order.debtor && order.debtor.code) || null}
                    labelText="Debtor"
                    options={debtors}
                    bulkPrice={{
                      showWarning: order.orderLines.length > 0,
                      initialValue: order.debtor && order.debtor.bulkPricing
                    }}
                  />
                )}
              />
            )}
            {!order.uiState.canEditDebtor && order.uiState.showDebtor && (
              <RoField
                value={order.debtor ? order.debtor.code : "-"}
                labelText="Debtor"
              />
            )}
            {isHireOrder(order) && (
              <React.Fragment>
                <RoDateField value={order.hireStartOn} labelText="Hire Start Date" />
                <RoDateField value={order.hireEndOn} labelText="Hire End Date" />
              </React.Fragment>
            )}
            <RoDateTimeField
              value={order.updatedAt}
              labelText="Last changed at"
            />
            {order.parentOrder && (
              <RoFieldWrapper labelText="Related Order">
                <OrderLink order={order.parentOrder}>
                  View #{order.parentOrder.cmpOrderNumber}
                </OrderLink>
              </RoFieldWrapper>
            )}
            {order.orderAllocations && order.orderAllocations.length > 0 && (
              <RoField value="Yes" labelText="Related Orders" />
            )}
            {allowPrivilegedFields(order) && !isOnDvaContract(order) && (
              <RoYesNo value={order.fromQuote} labelText="From Quote" />
            )}
            {allowPrivilegedFields(order) &&
              isHospitalOrder(order) &&
              !limitedEditableFields && (
                <EditYesNo
                  name="consignment"
                  onChange={this.handleChange}
                  value={order.consignment}
                  labelText="Consignment"
                  errors={errors}
                />
              )}
            {allowPrivilegedFields(order) && (
              <EditTextArea
                name="adminNotes"
                onChange={this.handleChange}
                value={order.adminNotes}
                labelText="Admin Notes"
                errors={errors}
              />
            )}
            <div className="form__actions">
              <input
                type="submit"
                name="commit"
                value="Update Order"
                disabled={!this.requiredFieldsComplete()}
                className="button primary"
              />
            </div>
          </fieldset>
        </div>
      </form>
    );
  }

  onSubmit = e => {
    e.preventDefault();
    let order = { id: this.props.order.id };
    // call update with any/only fields which have changed

    let fields = [
      "vic30DayDischarge",
      "dateReceived",
      "followUpOn",
      "npFollowUpOn",
      "priorApprovalEffectedAt",
      "hireStartOn",
      "costCentre",
      "cpo",
      "urgent",
      "consignment",
      "priorApprovalNumber",
      "complexHomeMods",
      "adminNotes"
    ];

    if (this.props.limitedEditableFields) {
      fields = [
        "followUpOn",
        "npFollowUpOn",
        "costCentre",
        "vic30DayDischarge",
        "adminNotes"
      ];
    }

    fields.map(f => {
      if (this.props.order[f] !== this.state.order[f]) {
        order[f] = this.state.order[f];
      }
    });

    // update any int values
    ["salesRepId", "debtorId"].map(f => {
      if (this.props.order[f] !== this.state.order[f]) {
        order[f] = parseInt(this.state.order[f]);
      }
    });
    order["updateSource"] = "OrderDetails";

    this.props.updateOrder(order);
    this.props.toggleEdit();
  };

  handleChange = (field, value) => {
    let order;
    if (field === "salesRep") {
      order = { ...this.state.order, [field]: value, salesRepId: value.id };
    } else if (field === "debtor") {
      order = { ...this.state.order, [field]: value, debtorId: value.id };
    } else {
      order = { ...this.state.order, [field]: value };
    }
    this.setState({ order });
  };

  requiredFieldsComplete = () => {
    const { order } = this.state;

    return (
      order.dateReceived &&
      (!isHospitalDischargeOrder(order) || order.vic30DayDischarge !== null) &&
      (!isHospitalOrder(order) || order.costCentre)
    );
    // field === "dateReceived" ||
    // (field === "vic30DayDischarge" &&
    //   isHospitalDischargeOrder(this.state.order)) ||
    // (field === "costCentre" && isHospitalOrder(this.state.order));
  };
}

OrderDetailsEdit.propTypes = {
  order: OrderObject,
  salesReps: PropTypes.array.isRequired,
  toggleEdit: PropTypes.func.isRequired,
  updateOrder: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  limitedEditableFields: PropTypes.bool.isRequired
};

export default OrderDetailsEdit;
